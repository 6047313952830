<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'TimSync'
  };
</script>

<style lang="scss">
  @import "./assets/scss/index";
  
</style>
