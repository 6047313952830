import ApiHttpClient from '@/libraries/http/ApiHttpClient';

class TrackerEvent {
  headers = {};
  data = {};

  customHeader(key, value) {
    this.headers[key] = value;
    return this;
  }

  customData(key, value) {
    this.data[key] = value;
    return this;
  }

  headerAccessKey(value) {
    this.headers["access_key"] = value;
    return this;
  }

  headerSchema(value) {
    this.headers["schema"] = value;
    return this;
  }

  hitType(value) {
    this.data["hit-type"] = value;
    return this;
  }

  eventCategory(value) {
    this.data["event-category"] = value;
    return this;
  }

  eventAction(value) {
    this.data["event-action"] = value;
    return this;
  }

  eventLabel(value) {
    this.data["event-label"] = value;
    return this;
  }

  eventValue(value) {
    this.data["event-value"] = value;
    return this;
  }

  eventData(value) {
    this.data["event-data"] = value;
    return this;
  }

  eventDate(value) {
    this.data["event-date"] = value;
    return this;
  }

  eventStatus(value) {
    this.data["event-status"] = value;
    return this;
  }

  eventInteractionNumber(value) {
    this.data["event-interactionNumber"] = value;
    return this;
  }

  eventError(value) {
    this.data["event-error"] = value;
    return this;
  }

  eventErrorDetail(value) {
    this.data["event-errorDetail"] = value;
    return this;
  }

  channel() {
    this.data["channel"] = this.data["channel"] || {};
    return this;
  }

  channelName(value) {
    this.data["channel"]["name"] = value;
    return this;
  }

  channelDeviceOS(value) {
    this.data["channel"]["deviceOS"] = value;
    return this;
  }

  channelDeviceModel(value) {
    this.data["channel"]["deviceModel"] = value;
    return this;
  }

  channelDeviceManufacturer(value) {
    this.data["channel"]["deviceManufacturer"] = value;
    return this;
  }

  channelAppVersion(value) {
    this.data["channel"]["appVersion"] = value;
    return this;
  }

  user() {
    this.data["user"] = this.data["user"] || {};
    return this;
  }

  userIMEI(value) {
    this.data["user"]["IMEI"] = value;
    return this;
  }

  userLogin(value) {
    this.data["user"]["login"] = value;
    return this;
  }

  userCPF(value) {
    this.data["user"]["CPF"] = value;
    return this;
  }

  userLoginType(value) {
    this.data["user"]["loginType"] = value;
    return this;
  }

  userSegment(value) {
    this.data["user"]["segment"] = value;
    return this;
  }

  userSubSegment(value) {
    this.data["user"]["subSegment"] = value;
    return this;
  }

  userLineType(value) {
    this.data["user"]["lineType"] = value;
    return this;
  }

  userPlanCode(value) {
    this.data["user"]["planCode"] = value;
    return this;
  }

  userPlanName(value) {
    this.data["user"]["planName"] = value;
    return this;
  }

  debug() {
    if (process.env.NODE_ENV === "development") {
      console.log("-----------------------------------");
      console.log("TRACKER EVENT");
      console.log("Data:");
      Object.keys(this.data).forEach((key) => {
        console.log(`${key}: `, this.data[key]);
      });
      console.log("");
      console.log("Headers:");
      Object.keys(this.headers).forEach((key) => {
        console.log(`${key}: `, this.headers[key]);
      });
      console.log("-----------------------------------");
    }

    return this;
  }

  emit() {
    return ApiHttpClient.createInstance().post(
      process.env.VUE_APP_ANALYTICS_URL,
      this.data,
      {
        headers: this.headers,
      }
    );
  }
}

export default TrackerEvent;
