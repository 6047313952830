<template>
  <main-template>
    <div
      class="offer-page"
      :class="{
        'offer-page--details': details,
        'offer-page--leave-effect': leaving
      }"
    >
      <!-- offer -->
      <div class="offer-page__offer">
        <div class="offer-page__offer__container">
          <div></div>
          <div class="offer-page__offer__header">
            <div class="offer-page__offer__image">
              <img
                class="offer-page__offer__image__clock"
                src="./../assets/img/watch-2.svg"
                alt="Relógio Digital"
                title="Relógio Digital"
              />
              <img
                class="offer-page__offer__image__logo"
                src="./../assets/img/ic-logo.svg"
                alt="Erro"
                title="Erro"
              />
            </div>
            <h2 v-html="title"></h2>
          </div>
          <div v-if="!offer"></div>
          <div v-if="offer" class="offer-page__offer__form">
            <div v-if="!multipleDevices">
              <h2 class="offer-page__offer__form__title">{{ offerPrice }}</h2>
              <p class="offer-page__offer__form__description" v-html="offerPromotion"></p>
              <div class="offer-page__offer__form__terms">
                <form-checkbox v-model="acceptedTerms"><span v-html="offerTerms"></span></form-checkbox>
              </div>
              <div class="offer-page__offer__form__control">
                <action-button @click="handleClickActivate" :disabled="!acceptedTerms">{{ offerActivateButton }}</action-button>
              </div>
            </div>
            <div v-if="multipleDevices">
              <h2 class="offer-page__offer__form__title">{{ multipleDevicesTitle }}</h2>
              <p class="offer-page__offer__form__description" v-html="multipleDevicesSubtitle"></p>
              <div class="offer-page__offer__form__control">
                <action-button @click="handleAddDevice">{{ multipleDevicesAddButton }}</action-button>
              </div>
            </div>
          </div>
        </div>
        <a @click="handleClickToggle" class="offer-page__offer__arrow">
          <img src="./../assets/img/icon-arrow-down.svg"/>
        </a>
      </div>
      <!-- end offer -->
      <!-- info -->
      <div
        v-if="details"
        :style="{
          marginTop: `-${parallaxSize}px`,
          paddingTop: `${parallaxSize}px`
        }"
        class="offer-page__info"
      >
        <div ref="detailsMarker" class="offer-page__info__container">
          <h2 class="offer-page__info__title">{{ offerDetailsTitle }}</h2>
          <h3 class="offer-page__info__subtitle">{{ offerDetailsSubtitle }}</h3>
          <h3 class="offer-page__info__features-title">{{ offerDetailsFeaturesTitle }}</h3>
          <div class="offer-page__info__features">

            <div
              v-for="(feature, index) in offerDetailsFeatures"
              :key="`feature-${index}`"
              class="offer-page__info__features__item"
            >
              <div class="offer-page__info__features__item__box">
                <div class="offer-page__info__features__item__image">
                  <img
                    :src="feature.icon"
                    :alt="feature.iconAlt"
                    :title="feature.iconTitle"
                  />
                </div>
                <p>{{ feature.label }}</p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- end info -->
    </div>
  </main-template>
</template>

<script>
  import { mapGetters } from 'vuex';
  import ActionButton from '@/components/ActionButton.vue';
  import FormCheckbox from '@/components/FormCheckbox.vue';
  import Application from '@/core/Application';
  import Tracker from '@/core/Tracker';
  import MainTemplate from '@/templates/Main.vue';
  import { getConfigurationValue } from '@/utils/configUtil';
  import { formatMoney } from '@/utils/numeralUtil';
  
 

  const ListOfScreens = {
    EXTRA_SMALL: 'EXTRA_SMALL',
    SMALL: 'SMALL'
  };

  const ListOfScreensSize = {
    [ListOfScreens.EXTRA_SMALL]: 0,
    [ListOfScreens.SMALL]: 414
  };

  const ListOfParallaxesSize = {
    [ListOfScreens.EXTRA_SMALL]: 154,
    [ListOfScreens.SMALL]: 182
  };

  const ListOfScrollMargin = {
    [ListOfScreens.EXTRA_SMALL]: 50,
    [ListOfScreens.SMALL]: 62
  };

  export default {
    name: 'Offer',
    data() {
      return {
        leaving: false,
        details: false,
        parallaxSize: 0,
        acceptedTerms: false
      };
    },
    computed: {
      title() {
        if (this.multipleDevices) {
          return getConfigurationValue(this.config, 'lbl-multisim-title', '');
        }

        return getConfigurationValue(this.config, 'lbl-title', '');
      },
      offerPrice() {
        return this.offer
          ? `R$ ${formatMoney(this.offer.plugin.value)}/mês`
          : '';
      },
      offerPromotion() {
        return getConfigurationValue(this.config, 'lbl-subtitle', '');
      },
      offerTerms() {
        return getConfigurationValue(this.config, 'lbl-term', '');
      },
      offerActivateButton() {
        return getConfigurationValue(this.config, 'btn-activate', '');
      },
      multipleDevices() {
        return (this.offer && this.offer.plugin.hasSecondaryDevices === 'Y');
      },
      multipleDevicesTitle() {
        return getConfigurationValue(this.config, 'lbl-multisim-name', '');
      },
      multipleDevicesSubtitle() {
        return getConfigurationValue(this.config, 'lbl-multisim-subtitle', '');
      },
      multipleDevicesAddButton() {
        return getConfigurationValue(this.config, 'btn-multisim-add', '');
      },
      offerDetailsTitle() {
        return getConfigurationValue(this.config, 'lbl-offer-details-title', '');
      },
      offerDetailsSubtitle() {
        return getConfigurationValue(this.config, 'lbl-offer-details-subtitle', '');
      },
      offerDetailsFeaturesTitle() {
        return getConfigurationValue(this.config, 'lbl-offer-details-features-title', '');
      },
      offerDetailsFeatures() {
        return getConfigurationValue(this.config, 'lst-offer-details-features', []);
      },
      apiErrorMessage() {
        return getConfigurationValue(this.config, 'lbl-api-err', '');
      },
      accessErrorMessage() {
        return getConfigurationValue(this.config, 'lbl-access-err', '');
      },
      maxDevicesErrorMessage() {
        return getConfigurationValue(this.config, 'lbl-max-devices-err', '');
      },
      ...mapGetters([
        'config',
        'offer',
        'offerErrorCode'
      ])
    },
    methods: {
      getDeviceWidth() {
        return (window.innerWidth > 0) ? window.innerWidth : screen.width;
      },
      getDeviceHeight() {
        return (window.innerHeight > 0) ? window.innerHeight : screen.height;
      },
      getDeviceScreen() {
        const width = this.getDeviceWidth();
        if (width < ListOfScreensSize[ListOfScreens.SMALL]) {
          return ListOfScreens.EXTRA_SMALL;
        }
        return ListOfScreens.SMALL;
      },
      getPageHeight() {
        const body = document.body;
        const html = document.documentElement;

        return Math.max(
          body.scrollHeight,
          body.offsetHeight,
          html.clientHeight,
          html.scrollHeight,
          html.offsetHeight
        );
      },
      getMaxScroll() {
        return this.getPageHeight() - this.getDeviceHeight();
      },
      getScrollPosition() {
        return window.scrollY;
      },
      getMaxParallaxSize() {
        const screen = this.getDeviceScreen();
        return ListOfParallaxesSize[screen];
      },
      addWindowScrollEvent() {
        window.addEventListener('scroll', this.handleScroll);
      },
      getScrollMargin() {
        const screen = this.getDeviceScreen();
        return ListOfScrollMargin[screen];
      },
      removeWindowScrollEvent() {
        window.removeEventListener('scroll', this.handleScroll);
      },
      scrollToTop() {
        try {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        } catch(e) {
          window.scrollTo(0, 0);
        }
      },
      scrollTopDetails() {
        const margin = this.getScrollMargin();
        const offset = this.$refs.detailsMarker.offsetTop;
        const scrollPosition = offset - margin;

        try {
          window.scrollTo({
            top: scrollPosition,
            behavior: 'smooth'
          });
        } catch(e) {
          window.scrollTo(0, scrollPosition);
        }
      },
      computeParallaxSize() {
        const maxScroll = this.getMaxScroll();
        const maxParallaxSize = this.getMaxParallaxSize();
        const scrollPosition = this.getScrollPosition();
        this.parallaxSize = parseInt((scrollPosition / maxScroll) * maxParallaxSize);
      },
      activateOffer() {

        if (this.multipleDevices){
          this.trackAddDevice();
        }else{
          this.trackOfferActivationSelection();
        }
        Application.activateOffer();
        this.runLeaveEffect();
        setTimeout(() => {
          this.$router.push({ name: 'loading' });
        }, 300);
      },
      showDetails() {
        this.details = true;
      },
      hideDetails() {
        this.details = false;
      },
      runLeaveEffect() {
        this.leaving = true;
      },
      handleClickToggle() {
        if (this.details) {
          this.scrollToTop();
          setTimeout(() => {
            this.hideDetails();
          }, 500);
        } else {
          this.showDetails();
          this.$nextTick(() => {
            this.scrollTopDetails();
          });
        }
      },
      handleScroll() {
        this.computeParallaxSize();
      },
      handleClickActivate() {
        if (this.acceptedTerms) {
          this.activateOffer();
        }
      },
      handleAddDevice() {
        this.activateOffer();
      },
      trackOfferActivationSelection() {
        Tracker
          .trackNavigation()
          .eventLabel('Tim Sync Ativar')
          .eventStatus('Sucesso')
          .userLogin(this.offer.msisdn)
          .userLoginType("CPF")
          .userLineType("Móvel")
          .userSegment('')
          .userSubSegment('')
          .userPlanCode(this.offer.plan ? this.offer.plan.commercialCode : '')
          .userPlanName(this.offer.plugin ? this.offer.plugin.name : '')
          .emit();
      },
      trackAddDevice(){
          Tracker
          .trackNavigation()
          .userLogin(this.offer.msisdn)
          .userLoginType("CPF")
          .userLineType("Móvel")
          .userSegment('')
          .userSubSegment('')
          .userPlanCode(this.offer ? this.offer.plan.commercialCode : '')
          .userPlanName(this.offer ? this.offer.plugin.name : '')
          .userCPF(this.offer ? this.offer.userCPF: '')
          .eventLabel('Tim Sync Ativar Adicional')
          .eventStatus('Sucesso')
          .emit();
      }
      
    },
    created() {
      this.addWindowScrollEvent();
    },
    beforeDestroy() {
      this.removeWindowScrollEvent();
    },
    components: {
      ActionButton,
      FormCheckbox,
      MainTemplate
    }
  };
</script>

<style lang="scss">
  @import "./../assets/scss/base/media-queries";
  @import "./../assets/scss/default";
  @import "./../assets/scss/modules/container";

  @keyframes leave-effect {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .offer-page__offer__image {
    position: relative;
    animation: clock-effect 0.3s 1;
  }

  .offer-page__offer__image__clock {
    display: block;
    width: 197px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }

  .offer-page__offer__image__logo {
    margin: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-100%);
    left: 0;
    right: 0;
    bottom: 0px;
    z-index: 1;
    width: 100% !important;
    height: 14% !important;
  }

  .offer-page {}

  .offer-page.offer-page--leave-effect {
    animation: 0.3s linear running leave-effect;
  }

  .offer-page__offer {
    position: relative;
  }

  .offer-page__offer__container {
    @extend .container;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 62px;
    padding-bottom: 50px;
    min-height: 100vh!important;
  }

  .offer-page__offer__header {}

  .offer-page__offer__header img {
    display: block;
    width: 106px;
    margin: 0 auto;
  }

  .offer-page__offer__header h2 {
    display: block;
    font-family: $font-family-primary;
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 1.15;
    text-align: center;
    color: #FFFFFF;
  }

  .offer-page__offer__arrow {
    position: absolute;
    bottom: 7px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 32px;
    height: 32px;
    z-index: 2;
    cursor: pointer;
    transition: transform 0.5s ease;
  }

  .offer-page--details .offer-page__offer__arrow {
    transform: rotate(-180deg)
  }

  .offer-page__offer__arrow:active {
    opacity: 0.7;
  }

  .offer-page__offer__form {
    margin-top: 18px;
    padding: 16px 15px;
    border-radius: 15px;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  }

  .offer-page__offer__form__title {
    display: block;
    font-family: $font-family-primary;
    font-weight: 300;
    font-size: 1.375rem;
    line-height: 1.22;
    text-align: center;
    color: #000000;
  }

  .offer-page__offer__form__description {
    display: block;
    margin-top: 10px;
    font-family: $font-family-primary;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.35;
    text-align: center;
    letter-spacing: -0.2px;
    color: #000000;
  }

  .offer-page__offer__form__terms {
    margin-top: 24px;
  }

  .offer-page__offer__form__control {
    margin-top: 24px;
  }

  .offer-page__info {
    background: #000000;
  }

  .offer-page__info__container {
    @extend .container;
    padding-top: 25px;
    padding-bottom: 32px;
  }

  .offer-page__info__title {
    display: block;
    font-family: $font-family-primary;
    font-weight: 500;
    font-size: 1.375rem;
    line-height: 1.22;
    text-align: center;
    color: white;
    mix-blend-mode: normal;
    opacity: 0.98;
    letter-spacing: -0.5px;
  }

  .offer-page__info__subtitle {
    display: block;
    margin-top: 32px;
    font-family: $font-family-primary;
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 1.22;
    text-align: center;
    color: #C1C1C1;
    letter-spacing: -0.5px;
    mix-blend-mode: normal;
    opacity: 0.98;
  }

  .offer-page__info__features-title {
    display: block;
    margin-top: 55px;
    font-family: $font-family-primary;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.25;
    text-align: center;
    color: #C1C1C1;
    letter-spacing: -0.5px;
  }

  .offer-page__info__features {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
  }

  .offer-page__info__features__item {
    width: 50%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .offer-page__info__features__item p {
    display: block;
    margin-top: 12px;
    font-family: $font-family-primary;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25;
    text-align: center;
    color: #C1C1C1;
    mix-blend-mode: normal;
  }

  .offer-page__info__features__item__image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    margin-left: auto;
    margin-right: auto;
  }

  .offer-page__info__features__item__image img {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }

  .offer-page__info__features__item__box {
    width: 112px;
    margin-left: auto;
    margin-right: auto;
  }

  @media all and #{$media-xsmall} {
    .offer-page__offer__header img {
      width: 118px;
    }

    .offer-page__offer__header h2 {
      max-width: 75%;
      margin-left: auto;
      margin-right: auto;
    }

    .offer-page__offer__form {
      margin-top: 22px;
      padding: 24px 20px 23px 20px;
    }

    .offer-page__info__container {
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  @media all and #{$media-small} {
    .offer-page__offer__container {
      padding-top: 88px;
      padding-bottom: 62px;
    }

    .offer-page__offer__header img {
      width: 172px;
    }

    .offer-page__offer__header h2 {
      font-size: 1.375rem;
      line-height: 1.2;
    }

    .offer-page__offer__form {
      margin-top: 32px;
      padding: 42px 32px 26px 32px;
    }

    .offer-page__offer__form__title {
      font-size: 2rem;
      line-height: 1.22;
    }

    .offer-page__offer__form__description {
      font-size: 1rem;
      line-height: 1.15;
      margin-top: 22px;
    }

    .offer-page__offer__form__terms {
      margin-top: 35px;
    }

    .offer-page__offer__form__control {
      margin-top: 35px;
    }

    .offer-page__info__container {
      padding-top: 25px;
      padding-bottom: 28px;
    }

    .offer-page__info__title {
      font-size: 2rem;
      line-height: 1.25;
    }

    .offer-page__info__subtitle {
      margin-top: 28px;
      font-size: 1.25rem;
    }

    .offer-page__info__features-title {
      margin-top: 52px;
      font-size: 1.25rem;
      line-height: 1.22;
    }

    .offer-page__info__features {
      margin-top: 23px;
    }

    .offer-page__info__features__item {
      padding-top: 17px;
      padding-bottom: 17px;
    }

    .offer-page__info__features__item__image {
      width: 30px;
      height: 30px;
    }

    .offer-page__offer__arrow {
      bottom: 12px;
    }
  }
</style>
