import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import App from '@/App.vue';
import InitialLoading from '@/pages/InitialLoading.vue';
import Server from '@/api/Server';
import Application from '@/core/Application';
import ApiHttpClient from '@/libraries/http/ApiHttpClient';
import router from '@/router';
import store from '@/store';
import { getConfigurationValue } from '@/utils/configUtil';
import * as numeralUtil from '@/utils/numeralUtil';
import { apiHttpClientModifier } from '@/utils/servicesUtil';
import VueTheMask from 'vue-the-mask';
import Glassbox from '@/helpers/getGlassbox';

//const DEFAULT_ERROR_MESSAGE = 'Nosso sistema está<br>temporariamente<br>indisponível';

Vue.config.productionTip = false;
Vue.use(VueTheMask);

if (process.env.NODE_ENV === 'development') {
    Server.start();
}

numeralUtil.setLocale('pt_BR');

ApiHttpClient.setModifier(apiHttpClientModifier);

new Vue({
    router,
    store,
    computed: {
        accessErrorMessage() {
            return getConfigurationValue(this.config, 'lbl-access-err', '');
        },
        apiErrorMessage() {
            return getConfigurationValue(this.config, 'lbl-api-err', '');
        },
        // hasType(){
        //     return this.type;
        // },
        ...mapState(['type']),
        ...mapGetters(['config']),
    },
    //us177075 - glassbox 09/03/2021 - noan//
    beforeCreate(){
        if(`${Glassbox.showGlassbox}`=== 'true') {
         if (process.env.NODE_ENV === 'production') {
           let scriptEl = document.createElement("script");
           scriptEl.setAttribute('type', 'text/javascript');
           scriptEl.setAttribute('id', '');
           scriptEl.setAttribute('src',`${Glassbox.glassBoxScript}`);
           console.log("inicio -- glassbox   " + Glassbox.glassBoxScript)
           let head = document.getElementsByTagName("head")[0];
           head.insertBefore(scriptEl, head.firstChild);
         } else {
           console.log('Script de Glassbox desativado em modo de desenvolvimento.');
         }
       }  
     },
    created() {
        Application.loadConfig().then((loaded) => {
            if (loaded) {
                if (!Application.hasSessionID()) {
                    console.log("Application has sessionid")
                    Application.importSessionID();
                }
                if (Application.hasSessionID()) {
                    Application.sessionInit().then((response) => {
                        if (response.data.type === 'new_secondary_device') {
                            this.$router.push({ path: '/login' });
                        } else if (response.data.type == 'manage_secondary_devices'){
                            this.$router.push({ path: '/faq' });
                        } else {
                            Application.showError({
                                message: this.apiErrorMessage,
                                action: 'try_again',
                            });
                        }
                    }).catch(() => {
                        Application.showError({
                            message: this.apiErrorMessage,
                            action: 'try_again',
                        });
                    })
                } else {
                    Application.showError({
                        message: this.accessErrorMessage,
                        action: 'try_again',
                    });
                }
            } else {
                Application.showError({
                    message: 'Ocorreu um erro!<br>Por favor, tente novamente mais tarde.',
                });
            }
        });
    },
    watch: {
        type() {
            this.$forceUpdate();
        }
    },
    render: function (h) {
        return this.type ?
            h(App) :
            h(InitialLoading)
    },
}).$mount('#app');