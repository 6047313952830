<template>
  <main-template>
    <div class="loading-page">
      <!-- loading -->
      <div class="loading-page__loading">
        <div class="loading-page__loading__container">
          <div class="loading-page__loading__header">
            <div class="loading-page__status_clock-container">
              <img
                class="loading-page__loading__header__clock"
                src="./../assets/img/watch-2.svg"
                alt="Relógio Digital"
                title="Relógio Digital"
              />
              <img
                class="loading-page__loading__header_bars"
                src="./../assets/img/bars.gif"
                alt="Barras"
                title="Barras"
              />
              <img
                class="loading-page__loading__header__radar"
                src="./../assets/img/radar.gif"
                alt="Radar"
                title="Radar"
              />
            </div>
            <h2 v-html="message"></h2>
          </div>
        </div>
      </div>
      <!-- end loading -->
    </div>
  </main-template>
</template>

<script>
import { mapState } from 'vuex';
import Application from '@/core/Application';
import MainTemplate from '@/templates/Main';
import { OfferStateEnum } from '@/store'
import { getConfigurationValue } from '@/utils/configUtil';
import Tracker from '@/core/Tracker';

export default {
  name: 'loading',
  data() {
    return {
      entering: true,
    };
  },
  computed: {
    message() {
      return getConfigurationValue(this.config, 'lbl-activation', '');
    },
    apiErrorMessage() {
      return getConfigurationValue(this.config, 'lbl-api-err', '');
    },
    ...mapState([
      'config',
      'offerState',
      'offer',
      'offerErrorCode',
      'errorMessage'
    ])
  },
  watch: {
    offerState() {
      this.handleActiveOffer();
    }
  },
  methods: {
    runComeEffect() {
      this.entering = true;
    },
    isActivatingOffer() {
      return (this.offerState === OfferStateEnum.Activating);
    },
    handleActiveOffer() {
      if (this.offerState === OfferStateEnum.Active) {
        setTimeout(() => {
          this.$router.push({ name: 'status' });
        }, 200);
      } else if (this.offerState === OfferStateEnum.ActivationError) {
         if (this.offer.plugin.hasSecondaryDevices == 'Y'){
          this.trackActivationNewDeviceError('Invalid state.');
        } else {
          this.trackActivationError();
        }
        Application.showError({
          message: this.apiErrorMessage,
          action: 'try_again'
        });
        
      } else {       
        throw new Error('Invalid state.');
      }
    },
    trackActivationNewDeviceError(){
      Tracker
        .trackNavigation()
        .eventLabel('Tim Sync Tela Erro Adicional')
        .eventStatus('Falha')
        .userLogin(this.offer.msisdn)
        .userLoginType("CPF")
        .userLineType("Móvel")
        .userSegment('')
        .userSubSegment('')
        .userPlanCode(this.offer.plan ? this.offer.plan.commercialCode : '')
        .userPlanName(this.offer.plugin ? this.offer.plugin.name : '')
        .eventError(this.offerErrorCode)
        .eventErrorDetail(this.errorMessage)
        .emit();

    },
    trackActivationError() {
      Tracker
        .trackNavigation()
        .eventLabel('Tim Sync Tela Erro Ativacao')
        .eventStatus('Falha')
        .userLogin(this.offer.msisdn)
        .userLoginType("CPF")
        .userLineType("Móvel")
        .userSegment('')
        .userSubSegment('')
        .userPlanCode(this.offer.plan ? this.offer.plan.commercialCode : '')
        .userPlanName(this.offer.plugin ? this.offer.plugin.name : '')
        .eventErrorDetail(this.errorMessage)
        .eventError(this.offerErrorCode)
        .emit();
    }
  },
  mounted() {
    if (!this.isActivatingOffer()) {
      this.handleActiveOffer();
    } else {
      // a aplicação deve aguardar o fim da ativação.
    }
  },
  components: {
    MainTemplate,
  },
};
</script>

<style lang="scss">
@import "./../assets/scss/base/media-queries";
@import "./../assets/scss/default";
@import "./../assets/scss/modules/container";

@keyframes enter-effect {
  from {
    opacity: 0;
    transform: translateY(-120px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.loading-page__loading {
  position: relative;
  padding-top: 68px;
  padding-bottom: 50px;
  min-height: 100vh !important;
}

.loading-page__loading__container {
  @extend .container;
}

.loading-page {
  animation: enter-effect 0.8s;
}

.loading-page__status_clock-container {
  position: relative;
}

.loading-page__loading__header__radar {
  display: block;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.07;
}

.loading-page__loading__header__clock {
  display: block;
  position: absolute;
  left: 50%;
  top: 30px;
  width: 197px;
  transform: translateX(-50%);
  z-index: 2;
}

.loading-page__loading__header_bars {
  margin: auto;
  position: absolute;
  top: 128px;
  left: 0;
  right: 0;
  z-index: 3;
}

.loading-page__loading__header h2 {
  display: block;
  margin-top: 12px;
  font-family: $font-family-primary;
  font-weight: 300;
  font-size: 1.375rem;
  line-height: 1.22;
  text-align: center;
  color: white;
}

@media all and #{$media-xsmall} {
  .loading-page__loading__header h2 {
    display: block;
    margin-top: 12px;
    margin-left: auto;
    margin-right: auto;
    width: 290px;
  }
}

@media all and #{$media-small} {
  .loading-page__loading {
    padding-top: 148px;
    padding-bottom: 80px;
  }

  .loading-page__loading__header__clock {
    top: 48px;
  }

  .loading-page__loading__header__radar {
    width: 334px;
  }

  .loading-page__loading__header_bars {
    top: 148px;
  }

  .loading-page__loading__header h2 {
    width: 340px;
    font-size: 1.75rem;
    line-height: 1.22;
  }
}
</style>
