import * as Cookie from 'tiny-cookie';
import ApplicationService from '@/services/Application';
import router from '@/router';
import generateUUID from '@/helpers/generateUUID';
import store, {
	ConfigStateEnum,
	OfferStateEnum,
	SendSmsStateEnum,
} from '@/store';
import { getConfigurationValue } from '@/utils/configUtil';
import { getConfigurationDescription } from '@/utils/configUtil';

export default class Application {
	static importSessionID() {
		const sessionID = window.location.pathname.replace("/", "");
		store.dispatch('setSessionID', sessionID);
	}

	static hasSessionID() {
		return store.getters.sessionID.length == 24;
	}

	static getType() {
		return store.getters.type.type;
	}

	static getCookieAccessKey() {
		return Cookie.get('access_key') || '';
	}

	static sessionInit() {
		const sessionID = store.getters.sessionID;
		const messageid = generateUUID()
			.toString()
			.replace('-', '')
			.substring(0, 23);

		const header = {
			messageid: messageid,
		};

		return new Promise((resolve) => {
			ApplicationService.sessionInit(sessionID, header)
				.then((response) => {
					store.dispatch('setType', response.data.type);
					resolve(response);
				})
				.catch(() => {
					resolve(false);
				});
		});
	}

	static loadEligibleOffer(login, password) {
		store.dispatch('changeOfferState', OfferStateEnum.Loading);

		const unformattedmsisdn = login.replace(/[^\d]/g, '');

		return new Promise((resolve) => {
			ApplicationService.getEligibleOffer(
				store.getters.sessionID,
				unformattedmsisdn,
				password
			)
				.then((response) => {
					store.dispatch('setOffer', response.data);
					store.dispatch('changeOfferState', OfferStateEnum.Loaded);
					resolve(true);
				})
				.catch((err) => {
					store.dispatch('setOfferError', err.response.data);
					store.dispatch('changeOfferState', OfferStateEnum.LoadingError);
					resolve(false);
				});
		});
	}

	static cancelSession(cancelURL, sessionCancelBody) {
		sessionCancelBody.session_id = store.getters.sessionID;
		// sessionCancelBody.session_id = null;
		if (!sessionCancelBody.session_id) {
			var errorMessage = getConfigurationValue(
				store.getters.config,
				'sessions-cancel-invalidsession'
			);
			this.showSessionError({
				message: errorMessage,
				action: 'try_again',
			});
		} else {
			let headers = {
				"Content-Type" : "application/json"
			}
			ApplicationService.cancelSession(cancelURL, sessionCancelBody, headers)
				.then((response) => {
					store.dispatch('resetState');
					window.sessionStorage.clear();
					window.location.replace(response.data.url);
				})
				.catch(() => {
					const errorMessage = getConfigurationValue(
						store.getters.config,
						'sessions-cancel-apierror'
					);
					this.showSessionError({
						message: errorMessage,
						action: 'try_again',
					});
				});
		}
	}

	static loadConfig() {
		store.dispatch('changeConfigState', ConfigStateEnum.Loading);

		return new Promise((resolve) => {
			ApplicationService.getConfig()
				.then((response) => {
					store.dispatch('setConfig', response.data);
					store.dispatch('changeConfigState', ConfigStateEnum.Loaded);
					resolve(true);
				})
				.catch(() => {
					store.dispatch('changeConfigState', ConfigStateEnum.Error);
					resolve(false);
				});
		});
	}

	static activateOffer() {
		return new Promise((resolve) => {
			store.dispatch('changeOfferState', OfferStateEnum.Activating);

			const partnerURL = getConfigurationValue(
				store.getters.config,
				'url-sessions-complete',
				''
			);

			let requestBody = JSON.stringify(getConfigurationValue(
				store.getters.config,
				'body-sessions-complete',
				''
			));

			requestBody = requestBody.replace('{session_id}', store.getters.sessionID);
			requestBody = requestBody.replace('{plugin.name}', store.getters.offer.plugin.name);
			requestBody = requestBody.replace('{plan.typeId}', store.getters.offer.plan.typeId);
			requestBody = requestBody.replace('{plugin.commercialCode}', store.getters.offer.plugin.commercialCode);

			let headers = {
				"Content-Type" : "application/json"
			}
			ApplicationService.activateOffer(partnerURL, requestBody, headers)
				.then((response) => {
					store.dispatch('changeOfferState', OfferStateEnum.Active);
					resolve(true);
					window.location.replace(response.data.url);
				})
				.catch(() => {
					store.dispatch('changeOfferState', OfferStateEnum.ActivationError);
					resolve(false);
				});
		});
	}
	static sendSms(msisdn) {
		return new Promise((resolve) => {
			store.dispatch('changeSendSmsState', SendSmsStateEnum.Loading);

			const partnerURL = getConfigurationValue(
				store.getters.config,
				'ocsg-smsmessaging-api',
				''
			);

			const partnerBody = getConfigurationValue(
				store.getters.config,
				'ocsg-smsmessaging-api-body',
				''
			);

			const unformattedmsisdn = msisdn.replace(/[^\d]/g, '');
			const partnerBodyFormatted = partnerBody.replace(
				'{msisdn}',
				unformattedmsisdn
			);

			const keys = {
				key1: 'ocsg-smsmessaging-header1',
				key2: 'ocsg-smsmessaging-header2',
				key3: 'ocsg-smsmessaging-header3',
				key4: 'ocsg-smsmessaging-header4',
				key5: 'ocsg-smsmessaging-header5'
				};

			const headers = this.buildHeaders(keys);

			ApplicationService.postOcsgSendSms(
				partnerURL,
				partnerBodyFormatted,
				headers
			)
				.then((response) => {
					store.dispatch('setSendSms', response.data);
					store.dispatch('changeSendSmsState', SendSmsStateEnum.Loaded);
					resolve(true);
				})
				.catch(() => {
					store.dispatch('changeSendSmsState', SendSmsStateEnum.Error);
					resolve(false);
					const errorMessage = getConfigurationValue(
						store.getters.config,
						'send-sms-erro'
					);
					this.showSessionError({
						message: errorMessage,
						action: 'try_again',
					});
				});
		});
	}

	static showSessionError(options) {
		store.dispatch('setError', options);
		router.push({ name: 'session-error' });
	}

	static tryAgain() {
		store.dispatch('clearError');
		const partnerURL = getConfigurationValue(
			store.getters.config,
			'url-access-err',
			''
		);
		window.location.replace(partnerURL);
	}

	static showError(options) {
		store.dispatch('setError', options);
		router.push({ name: 'error' });
	}

	static buildHeaders(keys) {
		const chaves = Object.keys(keys);
		const headers = {};
		
		var accessKey =  Cookie.get('access_key') || '';
		headers['access_key']= accessKey;
		headers['Content-Type']='application/json';
		
		for (let i = 1; i < chaves.length; i++) {
			const element = chaves[i];
			headers[
				getConfigurationValue(store.getters.config, keys[element])
			] = getConfigurationDescription(store.getters.config, keys[element]);
		}
		return headers;
	}
}
