const TrackerEventCategory = {
  Login: 1,
  Activation: 2,
  Recharge: 3,
  Navigation: 6,
  CustomerActivation: 7,
  Portability: 11,
  OptInOptOut: 10,
  SpecialCreditRequest: 20
};

export default TrackerEventCategory;
