<template>
  <main-template theme="white">
        <div class="error-page__session-error" >
            <div class="alert-box">
             <img class="alert-img"
              src="./../assets/img/ic-status-erro.svg"
              alt="Alerta"
              title="Alerta"
            />
            </div>
            <h1 class="title error-page-Session__Error__Message"><b>Sessão Expirada.</b></h1>
            <div class="spacer +big"></div>
            <h2 class="title error-page-Session__Error__Message">{{ errorMessage }}</h2>

            <div class="separator"></div>
            <div >
                <div class="spacer +big"></div>
                <action-button @click="backToBeginning()" class="btn" style="padding: 10px">Entrar Agora</action-button>
            </div>
        </div>
  </main-template>
</template>


<script >
import Vue from "vue";
import MainTemplate from '@/templates/Main.vue';
import ActionButton from '@/components/ActionButton.vue';
import { mapState } from 'vuex';
import Tracker from '@/core/Tracker';

export default Vue.extend({
    computed: {
    ...mapState([
        'errorStatus',
        'errorMessage',
        'errorAction',
        'errorOnCreated',
        'errorOnClickAction',
        'offer',
        'offerErrorCode'
      ])
    },
    methods: {
        backToBeginning() {
          this.trackLogin()
          this.$router.push({ name: 'login' });
        },
        trackLogin() {

          Tracker
            .trackLogin()
            .userLogin(this.offer ? this.offer.msisdn : '' )
            .userLineType("Móvel")
            .userLoginType("CPF")
            .userSegment('')
            .userSubSegment('')
            .userPlanCode(this.offer ? this.offer.plan.commercialCode : '')
            .userPlanName(this.offer ? this.offer.plugin.name : '')
            .userCPF(this.offer ? this.offer.userCPF: '')
            .eventLabel('Tim Sync Sessao Expirada')
            .eventError(this.offerErrorCode ? this.offerErrorCode : '')
            .eventErrorDetail(this.errorMessage ? this.errorMessage : '')
            .eventStatus('Falha')
            .emit();
        
        }
    },
    components: {
      MainTemplate,
      ActionButton
    }
});
</script>

<style lang="scss">
@import "./../assets/scss/base/media-queries";
@import "./../assets/scss/default";
@import "./../assets/scss/modules/container";

.error-page__session-error {
  padding-top: 221px;
  padding-left: 24px;
  padding-right: 24px;
}

.error-page-Session__Error__Message:first-child {
  margin-bottom : 20px
}

.error-page-Session__Error__Message {
  display: block;
  font-family: $font-family-primary;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.25;
  text-align: center;
  color: black;
  margin-bottom: 35px;
}

.alert-box {
  margin-bottom: 24px;
}

.alert-img {
  margin-left: 50%;
  margin-right: 50%;
  width: 32px;
  height: 32px;
  transform: translateX(-50%)
}

.error-page__error {
  position: relative;
  padding-top: 104px;
  padding-bottom: 114px;
  min-height: 100vh !important;
}

</style>
