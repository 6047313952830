import ApiHttpClient from '@/libraries/http/ApiHttpClient';
import Encryption from '@/utils/Encryption';

export default class Application {
	static sessionInit(sessionID, header) {
		return ApiHttpClient.createInstance().get(
			'/sessioninit',
			{
				params: {
					sessionId: sessionID,
				},
			},
			header
		);
	}

	static getEligibleOffer(sessionID, login, password) {
		var passEncrypted = Encryption.encrypt(password);
		var passEncryptedB64 = Encryption.base64Encode(passEncrypted);

		return ApiHttpClient.createInstance().get('/eligibilityMultiSIM', {
			params: {
				sessionId: sessionID,
			},
			headers: {
				msisdn: login,
				pass: passEncryptedB64,
			},
		});
	}

	static getConfig() {
		return ApiHttpClient.createInstance().get('/config');
	}

	static async postOcsgSendSms(url, body, headers) {
		return ApiHttpClient.createInstance().post(url, body, {headers: headers});
	}

	static cancelSession(cancelUrl, sessionCancelBody, headers) {
		return ApiHttpClient.createInstance().post(cancelUrl, sessionCancelBody,  {
			headers: headers
		});
	}

	static activateOffer(url, offer, headers) {
		return ApiHttpClient.createInstance().post(url, offer, {
			headers: headers,
		});
	}
}