const Config = require('@/utils/configUtil');
const Blowfish = require("javascript-blowfish").Blowfish
const ConfigFile = require('../../config/default.json');

const key = Config.getConfigurationValue(ConfigFile, 'crypt_key');
var bf =  new Blowfish(key, "ecb");

export default class Encryption {

    static encrypt(value) {
        var encryptedString = bf.encrypt(value);
        // encryptedString = bf.trimZeros(encryptedString);

        return encryptedString;
    }

    static decrypt(value) {
        var decryptedString = bf.base64Decode(value);
        decryptedString = bf.trimZeros(decryptedString);
        decryptedString = bf.decrypt(decryptedString);
        decryptedString = bf.trimZeros(decryptedString).replace(/[\u{0004}-]/gu, "");
        decryptedString = bf.trimZeros(decryptedString).replace(/[\u{0002}-]/gu, "");
        return decryptedString;
    }

    static base64Encode(value) {
        return bf.base64Encode(value);
    }
}
