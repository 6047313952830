<template>
  <a
    class="close-button"
    :class="[
      `close-button--theme-${theme}`,
    ]"
    @click="onClick"
  >
    <span><slot></slot></span>
  </a>
</template>

<script>
  export const ThemeEnum = {
    DARK_FULL: 'dark_full',
    DARK_BORDERED: 'dark_bordered',
    LIGHT_FULL: 'light_full',
    LIGHT_BORDERED: 'light_bordered'
  };

  
  export default {
    name: 'CloseButton',
    props: {
      theme: {
        type: String,
        default: ThemeEnum.DARK_FULL
      },
      cancelUrl: String,
      sessionCancelBody : Object
    },
    methods: {
      onClick() {
         this.$emit('cancel', this.cancelUrl, this.sessionCancelBody);
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "./../assets/scss/base/variables";

  .close-button {
    display: flex;
    float: right;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: 1px solid transparent;
    border-radius: 3px;
    text-align: center;
    font-family: $font-family-primary;
    font-size: 1rem;
    font-weight: 450;
    line-height: normal;
    margin-right: 5%;
    cursor: pointer;
    box-sizing: content-box;
    user-select: none;
  }

  /* theme dark full */

  .close-button.close-button--theme-dark_full {
    background-color: #001ccf;
    font-size: 0.9rem;
    line-height: unset;
    border-color: #001ccf;
    color: #FFFFFF;
    transition: background-color 0.4s ease;
  }

  .close-button.close-button--theme-dark_full:active {
    background-color: rgba(#001ccf, 0.7);
  }

  .close-button-disabled.close-button.close-button--theme-dark_full {
    opacity: 0.2;
    cursor: default;
  }

    .close-button.close-button--theme-light_full {
    background-color: white;
    border-color: white;
    color: #001ccf ;
    transition: background-color 0.4s ease;
  }

  .close-button.close-button--theme-light_full:active {
    background-color: rgba(white, 0.7);
  }

  .close-button-disabled.close-button.close-button--theme-light_full {
    opacity: 0.2;
    cursor: default;
  }
</style>
