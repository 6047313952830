<template>
    <div :class="open ? 'faq open' : 'faq'" @click="$emit('toggleOpen', index)"> 
         <div class="question-container">
            <!-- <div class="blue-lateral"></div> -->
            <div class="question" v-html="faq.key"></div>
         </div>
         <!-- <div class="question" v-html="faq.key"></div> -->
         <div class="answer" v-html="faq.value"></div>

    </div>
</template>

<script>
export default {
    name: 'FAQ',
    props: ['faq', 'index', 'open']
}
</script>
