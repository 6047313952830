import axios from 'axios';

export default class ApiHttpClient {
  static _options = {};
  static _modifier = (options) => options;

  static setDefaultOptions(defaultOptions) {
    ApiHttpClient._options = defaultOptions;
  }

  static setModifier(modifier) {
    ApiHttpClient._modifier = modifier;
  }

  static createInstance() {
    const options = ApiHttpClient._modifier(ApiHttpClient._options);
    return axios.create(options);
  }
}
