<template>
  <main-template theme="white">
    <div class="faq-page">
      <!-- faq -->
      <div class="faq-page__status">
        <div class="faq-page__status__blue-background"></div>
        <div class="faq-page__status__white-background"></div>
        <div class="faq-page__status__container">
          <div class="faq-page__status__header">
            
            <h1 class="info-h2" v-html="title"></h1>
            <p class="faq-page__status__header-description" v-html="message"></p>
            
        
        </div>
        
        <!-- starting accordion -->
          
          <div class="faqs">
              <!-- <div class="blue_lateral"> </div> -->
             <FAQ v-for="(faq, i) in faqs" 
             :faq="faq" 
             :index="i" 
             :key="i" 
             :open="faq.open = (faq.open != undefined ? faq.open : faq.open = false)"
             @toggleOpen="toggleOpen"
             /> 
          </div>       
        </div>   
        <div class="faq-page__status__header-more-info">
          <div class="faq-page__status__header-more-info__container">
            <p class="faq-page__status__header-more-info__text" v-html="footer"></p>
          </div>
        </div>
      </div>
      <!-- end faq -->
    </div>
  </main-template>
</template>

<script>
import { mapState } from 'vuex';
//import Tracker from '@/core/Tracker';
import MainTemplate from "@/templates/Main";
import { getConfigurationValue } from '@/utils/configUtil';
import configMock from '@/api/mocks/configTIMSyncContentSuccess.json';
import FAQ from '@/components/FAQ.vue';
// import ApplicationService from '../services/Application';



export default {
  name: "faq",
  computed: {
  
    ...mapState([
      'config'
    ])
  },
  
  data() {
    // var config =  ApplicationService.getConfig('lbl-buckets-tim-sync-json');
    var config =  getConfigurationValue(configMock, 'lbl-buckets-tim-sync-json', ''); //ambiente local dev
    
      return {
        faqs :  config.buckets,
        title:  config.title,
        message:  config.message,
        footer: config.footer

      }
  },
  methods: {
      toggleOpen: function (index){
          this.faqs = this.faqs.map((faq, i) => {
              if (index === i){
                  faq.open = !faq.open;
              }
              return faq;
          });
      } 

  },

  
  components: {
    MainTemplate,
    FAQ
 },
};
</script>

<style lang="scss">
@import "./../assets/scss/base/media-queries";
@import "./../assets/scss/default";
@import "./../assets/scss/modules/container";



.faq-page__status {
  position: relative;
  padding-top: 100px;
  padding-bottom: 82px;
  min-height: 100vh !important;
  overflow: hidden;
}


.faq-page__status__white-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: 2;
  animation: expand-effect 2.4s;
}

.faq-page__status__container {
  @extend .container;
  position: relative;
  padding: 0px 29px;
  z-index: 3;
}

.faq-page__status__header {
  display: block;
 
  margin: 0 auto;
  
  
}
.faq-page__status__header h1 {
   width: 272px;
  height: 78px;
  margin: 40px 4px 24px 0;
  font-family: TIMSans;
  font-size: 32px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.faq-page__status__header-description {
  font-family: $font-family-primary;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  width: 100% !important;
  color: $color-gray;
  margin-top: 24px;
  animation: opacity-effect-2 1s;

}


.faq-page__status__header-more-info {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 25px;
  z-index: 100;
  animation: opacity-effect-4 1.4s;
}

.faq-page__status__header-more-info__container {
  @extend .container;
  padding: 0px 29px;
}

.faq-page__status__header-more-info__text {
  display: block;
  font-family: $font-family-primary;
  font-size: 0.95rem;
  line-height: 16px;
  line-height: 1.5;
  text-align: left;
  color: $color-gray;
  font-size: 16px;
  width: 350px;
  padding-bottom: 20px;
}
.faq-page__status__header-more-info__text a {
  color: #0026d9;
  text-decoration: underline;
}
.faq-page__status {
    padding-top: 100px;
    padding-bottom: 100px;
  }
.blue-lateral{
    width: 4px;
    height: 50px;
    background-color: #0026d9;
    // margin-right: 8px;
    border-radius: 8px;
}
  .faq-page__status__header h1 {
    margin: auto;
    font-family: $font-family-primary;
    font-size: 32px;
    line-height: normal;
    align-content: center;
    padding: 0%;
    text-align: center;
  }

  .faq-page__status__header-more-info {
    line-height: 22px;
  }

  .faq-page__status__header-more-info {
    bottom: 50px;
  }

  .faq-page__status__header-more-info__text {
    line-height: 1.84;
  }
.faqs {
    margin-top: 48px;
}
.faq {
  
    display: block;
    width: 100%;
    max-width: 768px;
    margin : 15px auto;
    padding-bottom:16px;
    border-radius: 0px;
    border-bottom: 1px solid #aca1a1;
    
    background-color: #ffffff;

}
.faq:last-child {
  border-bottom: none;
  margin-bottom: 34px;
}
.question-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.question {
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: center;
    position: relative;
    color: #000000;
    font-size: 20px;
    font-weight: 500;
    transition: all 0.4s linear;
    font-family: $font-family-primary;
    box-shadow: 0px 0px 0px rgba(12, 27, 163);
    word-wrap:  normal;
    border-left: 1px;
    border-width: 4px;
    min-height: 50px;
    width: 100%;
    line-height: 1.6;
    padding: 15px 16px;
    margin-left: 0px;
}

.faq .question::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);

  width: 4px;
  height: 65%;
  box-sizing: border-box;
  border-radius: 8px;
  background: #0026d9;
}

.faq .question::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%) rotate(0deg);
      

    width: 30px;
    height: 30px;
    background-image: url('./../assets/img/down-arrow-image.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.2s linear;
}
.faq .open .question{
    margin-bottom: 15px;

}
.faq.open .question::after{
    transform:translateY(-50%) rotate(-180deg);
}
.faq .answer {
    color: #3c3c3c;
    font-size: 18px;
    opacity: 0;
    max-height: 0px;
    overflow-y: hidden;
    transition: all 0.4s ease-out;
    font-family: $font-family-primary;
    color: #666666;
}
.faq.open .answer {
    opacity: 1;
    max-height: 1000px;


}

// @media all and #{$media-small}{
//   .faq-page__status {
//     padding-top: 100px;
//     padding-bottom: 148px;
//   }
// .blue-lateral{
//     width: 4px;
//     height: 50px;
//     background-color: #0026d9;
//     // margin-right: 8px;
//     border-radius: 8px;
// }
//   .faq-page__status__header h1 {
//     margin: auto;
//     font-family: $font-family-primary;
//     font-size: 32px;
//     line-height: normal;
//     align-content: center;
//     padding: 0%;
//     text-align: center;
//   }

//   .faq-page__status__header-more-info {
//     line-height: 22px;
//   }

//   .faq-page__status__header-more-info {
//     bottom: 50px;
//   }

//   .faq-page__status__header-more-info__text {
//     line-height: 1.84;
//   }
// .faqs {
//     margin-top: 48px;
// }
// .faq {
  
//     display: block;
//     width: 100%;
//     max-width: 768px;
//     margin : 15px auto;
//     padding-bottom:16px;
//     border-radius: 0px;
//     border-bottom: 1px solid #aca1a1;
    
//     background-color: #ffffff;

// }
// .faq:last-child {
//   border-bottom: none;

// }
// .question-container {
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   // height: auto;
//   width: 100%;
// }
// .question {
//     display: flex;
//     flex-direction: row;
//     text-align: justify;
//     align-items: center;
//     position: relative;
//     color: #000000;
//     font-size: 20px;
//     font-weight: 500;
//     transition: all 0.4s linear;
//     font-family: $font-family-primary;
//     box-shadow: 0px 0px 0px rgba(12, 27, 163);
//     word-wrap:  normal;
//     border-left: 1px;
//     border-width: 4px;
//     min-height: 50px;
//     width: 100%;
//     line-height: 32px;
//     padding: 15px 16px;
//     margin-left: 0px;
// }

// .faq .question::before {
//   content: '';
//   position: absolute;
//   top: 50%;
//   left: 0px;
//   transform: translateY(-50%);

//   width: 4px;
//   height: 65%;
//   box-sizing: border-box;
//   border-radius: 8px;
//   background: #0026d9;
// }

// .faq .question::after {
//     content: '';
//     position: absolute;
//     top: 50%;
//     right: 0px;
//     transform: translateY(-50%) rotate(0deg);
      

//     width: 30px;
//     height: 30px;
//     background-image: url('./../assets/img/down-arrow-image.svg');
//     background-position: center;
//     background-size: contain;
//     background-repeat: no-repeat;
//     transition: all 0.2s linear;
// }
// .faq .open .question{
//     margin-bottom: 15px;

// }
// .faq.open .question::after{
//     transform:translateY(-50%) rotate(-180deg);
// }
// .faq .answer {
//     color: #3c3c3c;
//     font-size: 18px;
//     opacity: 0;
//     max-height: 0px;
//     overflow-y: hidden;
//     transition: all 0.4s ease-out;
//     font-family: $font-family-primary;
//     color: #666666;
// }
// .faq.open .answer {
//     opacity: 1;
//     max-height: 1000px;


// }


// }
</style>
