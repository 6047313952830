import Application from '@/core/Application';
import generateUUID from '@/helpers/generateUUID';

export const apiHttpClientModifier = (options = {}) => {
  return {...options, ...{
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
      access_key: Application.getCookieAccessKey(),
      messageid: generateUUID()
    }
  }};
};
