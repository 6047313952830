import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export const OfferStateEnum = {
  NotStarted: 0,
  Loading: 1,
  Loaded: 2,
  Activating: 3,
  Active: 4,
  LoadingError: 5,
  ActivationError: 6
};

export const ConfigStateEnum = {
  NotStarted: 0,
  Loading: 1,
  Loaded: 2,
  Error: 3
};

export const SendSmsStateEnum = {
  NotStarted: 0,
  Loading: 1,
  Loaded: 2,
  Error: 3
};

export const storeModel = {
  plugins: [createPersistedState({
    storage: window.sessionStorage,
})],
  state: {
    sessionID: '',
    type: '',
    offerState: OfferStateEnum.NotStarted,
    offer: null,
    offerErrorCode: 0,
    erroDescription: "",
    configState: ConfigStateEnum.NotStarted,
    config: [],
    configErrorCode: 0,
    errorStatus: false,
    errorMessage: '',
    errorAction: '',
    errorOnCreated: null,
    errorOnClickAction: null,
    sendSmsState: SendSmsStateEnum.NotStarted,
    sendSms: {},
  },
  getters: {
    sessionID(state) {
      return state.sessionID;
    },
    type(state) {
      return state.type;
    },
    offerState(state) {
      return state.offerState;
    },
    offer(state) {
      return state.offer;
    },
    offerErrorCode(state) {
      return state.offerErrorCode;
    },
    erroDescription(state){
      return state.erroDescription;

    }
    ,
    configState(state) {
      return state.configState;
    },
    config(state) {
      return state.config;
    },
    configErrorCode(state) {
      return state.configErrorCode;
    },
    errorStatus(state) {
      return state.errorStatus;
    },
    errorMessage(state) {
      return state.errorMessage;
    },
    errorAction(state) {
      return state.errorAction;
    },
    errorOnCreated(state) {
      return state.errorOnCreated;
    },
    errorOnClickAction(state) {
      return state.errorOnClickAction;
    },
    sendSmsState(state) {
      return state.sendSmsState;
    }
  },
  mutations: {
    setSessionID(state, value) {
      state.sessionID = value;
    },
    setType(state, value) {
      state.type = value;
    },
    changeOfferState(state, value) {
      state.offerState = value;
    },
    setOffer(state, value) {
      state.offer = value;
    },
    setOfferError(state, data) {
      state.offerState = data.state;
      state.offerErrorCode = data.status;
      state.erroDescription = data.description;
    },
    changeConfigState(state, value) {
      state.configState = value;
    },
    setConfig(state, value) {
      state.config = value;
    },
    setConfigError(state, code) {
      state.configErrorCode = code;
      state.configState = ConfigStateEnum.Error;
    },
    setError(state, options) {
      state.errorStatus = true;
      state.errorMessage = options.message;
      state.errorAction = options.action || '';
      state.errorOnCreated = options.onCreated || null;
      state.errorOnClickAction = options.onClickAction || null;
    },
    clearError(state) {
      state.errorStatus = false;
      state.errorMessage = '';
      state.errorAction = '';
      state.errorOnCreated = null;
      state.errorOnClickAction = null;
    },
    changeSendSmsState(state, value) {
      state.sendSmsState = value;
    },
    setSendSms(state, value) {
      state.sendSms = value;
    },
    resetState(state) {
      var newState = {
          sessionID: '',
          offerState: OfferStateEnum.NotStarted,
          offer: null,
          configState: ConfigStateEnum.NotStarted,
          config: [],
          errorStatus: false,
          errorMessage: '',
          errorAction: '',
          errorOnCreated: null,
          errorOnClickAction: null
        }
      var newStateKeys = Object.keys(newState)
      Object.keys(state).forEach(key => {
        if (key in newStateKeys) {
          state[key] = newState[key];
        } else {
          state[key] = null;
        }
      })
    }
  },
  actions: {
    setSessionID({ commit }, value) {
      commit('setSessionID', value);
    },
    setType({ commit }, value) {
      commit('setType', value);
    },
    changeOfferState({ commit }, value) {
      commit('changeOfferState', value);
    },
    setOffer({ commit }, value) {
      commit('setOffer', value);
    },
    setOfferError({ commit }, data) {
      commit('setOfferError', data);
    },
    changeConfigState({ commit }, value) {
      commit('changeConfigState', value);
    },
    setConfig({ commit }, value) {
      commit('setConfig', value);
    },
    setConfigError({ commit }, code) {
      commit('setConfigError', code);
    },
    setError({ commit }, options) {
      commit('setError', options);
    },
    clearError({ commit }) {
      commit('clearError');
    },
    changeSendSmsState({ commit }, value) {
      commit('changeSendSmsState', value);
    },
    setSendSms({ commit }, value) {
      commit('setSendSms', value);
    },
  }
};

export default new Vuex.Store(storeModel);
