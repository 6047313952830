import moment from 'moment';
import Device from '@/libraries/Device';
import TrackerEvent from '@/libraries/tracker/TrackerEvent';
import TrackerEventCategory from '@/libraries/tracker/TrackerEventCategory';

class Tracker {
    static EVENT_DATE_FORMAT = 'DD/MM/YYYY HH:mm:ss';
    static EVENT_HEADER_SCHEMA_BI = 'bi';

    static createEvent(category) {
        const event = new TrackerEvent();
        const device = new Device();

        event
            .headerSchema(Tracker.EVENT_HEADER_SCHEMA_BI)
            .hitType('')
            .eventCategory(String(category))
            .eventDate(moment().format(Tracker.EVENT_DATE_FORMAT))
            .channel()
            .channelName('3')
            .channelDeviceOS(device.getOS())
            .channelDeviceModel(device.getModel())
            .channelDeviceManufacturer(device.getManufacturer())
            .channelAppVersion(device.getOSVersion())
            .user()
            .userLogin('')
            .userCPF('')
            .userIMEI('')
            .userLoginType('')
            .userLineType('')
            .userSegment('')
            .userSubSegment('')
            .userPlanCode('')
            .userPlanName('')

        return event;
    }

    static trackNavigation() {
        return Tracker.createEvent(TrackerEventCategory.Navigation);
    }

    static trackActivation() {
        return Tracker.createEvent(TrackerEventCategory.Activation);
    }

    static trackRecharge() {
        return Tracker.createEvent(TrackerEventCategory.Recharge);
    }

    static trackCustomerActivation() {
        return Tracker.createEvent(TrackerEventCategory.CustomerActivation);
    }

    static trackPortability() {
        return Tracker.createEvent(TrackerEventCategory.Portability);
    }

    static trackOptInOptOut() {
        return Tracker.createEvent(TrackerEventCategory.OptInOptOut);
    }

    static trackLogin() {
        return Tracker.createEvent(TrackerEventCategory.Login);
    }

    static trackSpecialCreditRequest() {
        return Tracker.createEvent(TrackerEventCategory.SpecialCreditRequest);
    }
}

export default Tracker;