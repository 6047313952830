<template>
  <div class="main-template" v-bind:class="[theme ? theme + '-theme' : '']">
    <div class="main-template__header">
      <div class="img-frame">
        <img  :class="getImgClass"
          :src="getImgSrc"
          alt="TIM SYNC"
          title="TIM SYNC"
        />
        </div>
        <close-button
          :cancelUrl="getCancelurl"
          :sessionCancelBody="getCancelBody"
          :theme="getFecharTheme"
          @cancel="cancelSession"
          >Fechar</close-button>
    </div>
    <slot />
  </div>
</template>

<script>
import CloseButton from '../components/CloseButton.vue';
import Application from '../core/Application'
import { getConfigurationValue } from '@/utils/configUtil';
import { mapGetters } from 'vuex';



export default {
  components: { CloseButton },
  name: "MainTemplate",
  props: ["theme", "page"],
  computed : {
    getCancelurl() {
      return this.config.length > 0 ? getConfigurationValue(this.config, "sessions-cancel-url") : '';
    },
    getCancelBody() {       
      var sessionCancelBodyRaw = this.config.length > 0 ? getConfigurationValue(this.config, "sessions-cancel-body") : '';
      return sessionCancelBodyRaw !== '' ? JSON.parse(sessionCancelBodyRaw) : {};
    },
    getImgClass(theme) {
      if (theme.page === 'login') {
        return 'login-logo';
      } else {
        return 'tim-logo';
      }

    },
    getFecharTheme(theme) {
      if (theme.theme === 'white' || theme.theme === 'blue') {
        return 'light_full';
      } else {
        return 'dark_full';
      }
    } ,
    getImgSrc(theme) {
      if (theme.theme === 'white') {
        if (theme.page ==='login') {
          return require("../assets/img/logo-tim.svg");
        } else {
          return require("../assets/img/TIMSync16px-azul.svg");
        }
      } else if (theme.theme === 'blue') {
        return require("../assets/img/TIMSync16px-branco.svg");
      }
      
      return require("../assets/img/TIMSync16px-branco.svg");
    },
    ...mapGetters([
      'config',
      'type'
    ]),  
  },
  methods: {
    cancelSession(cancelUrl, sessionCancelBody) {
      Application.cancelSession(cancelUrl, sessionCancelBody);
    }
  }
};
</script>

<style lang="scss">
@import "./../assets/scss/base/media-queries";

.main-template {
  position: relative;
  background-color: #001ccf;
}

.white-theme {
  background-color: white;
}

.blue-theme {
   background-color: white;
}

.main-template__header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 24px;
  text-align: center;
  font-size: 0;
  display: block;
  z-index: 1000;
}

.main-template__header > img {
  width: 108px;
}
.tim-logo {
  max-width: 108px;
}
.img-frame {
  width: 40%;
  position: absolute;
  margin-left: 30%;
  margin-right: 30%;
}

.main-template__header > img.login-logo {
    width: 64.5px;
    height: 16px;
}

@media all and #{$media-small} {
  .main-template__header {
    padding-top: 40px;
  }

  .main-template__header > img {
    width: 160px;
  }

  .main-template__header > img.login-logo {
    width: 64.5px;
    height: 16px;
  }
}
</style>
