<template>
  <main-template theme="white">
    <div class="status-page">
      <!-- status -->
      <div class="status-page__status">
        <div class="status-page__status__blue-background"></div>
        <div class="status-page__status__white-background"></div>
        <div class="status-page__status__container">
          <div class="status-page__status__header">
            <div class="status-page__status_clock-container">
              <img
                class="status-page__status__header__clock"
                src="./../assets/img/watch-2.svg"
                alt="Relógio Digital"
                title="Relógio Digital"
              />
              <img
                class="status-page__status__header_check"
                src="./../assets/img/check.svg"
                alt="Barras"
                title="Barras"
              />
            </div>
            <h2 v-html="statusMessage"></h2>
            <p class="status-page__status__header-description" v-html="descriptionMessage"></p>
            <p class="status-page__status__header-protocol" v-html="protocolMessage"></p>
          </div>
        </div>
        <div class="status-page__status__header-more-info">
          <div class="status-page__status__header-more-info__container">
            <p class="status-page__status__header-more-info__text" v-html="footerHTML"></p>
          </div>
        </div>
      </div>
      <!-- end status -->
    </div>
  </main-template>
</template>

<script>
import { mapState } from 'vuex';
import Tracker from '@/core/Tracker';
import MainTemplate from "@/templates/Main";
import { getConfigurationValue } from '@/utils/configUtil';

export default {
  name: "status",
  computed: {
    statusMessage() {
      return getConfigurationValue(this.config, 'lbl-activation-success', '');
    },
    descriptionMessage() {
      return getConfigurationValue(this.config, 'lbl-activation-description', '');
    },
    protocolMessage() {
      return getConfigurationValue(this.config, 'lbl-activation-protocol', '');
    },
    footerHTML() {
      return getConfigurationValue(this.config, 'lbl-footer', '');
    },
    ...mapState([
      'config',
      'offer'
    ])
  },
  methods: {
    trackActivationSuccess() {
      Tracker
        .trackNavigation()
        .eventLabel('Tim Sync Tela Sucesso')
        .eventStatus('Sucesso')
        .userLogin(this.offer.msisdn)
        .userLoginType("CPF")
        .userLineType("Móvel")
        .userSegment('')
        .userSubSegment('')
        .userPlanCode(this.offer.plan ? this.offer.plan.commercialCode : '')
        .userPlanName(this.offer.plugin ? this.offer.plugin.name : '')
        .emit();  
    },
    trackActivationNewDeviceSuccess(){
      Tracker
        .trackNavigation()
        .eventLabel('Tim Sync Tela Sucesso Adicional')
        .eventStatus('Sucesso')
        .userLogin(this.offer.msisdn)
        .userLoginType("CPF")
        .userLineType("Móvel")
        .userSegment('')
        .userSubSegment('')
        .userPlanCode(this.offer.plan ? this.offer.plan.commercialCode : '')
        .userPlanName(this.offer.plugin ? this.offer.plugin.name : '')
        .emit();

    }
  },
  mounted() {    
    console.log("Mounted");
    if (this.offer.plugin.hasSecondaryDevices == 'Y'){
      this.trackActivationNewDeviceSuccess();
    }else{
      this.trackActivationSuccess();
    }
  },
  components: {
    MainTemplate,
  },
};
</script>

<style lang="scss">
@import "./../assets/scss/base/media-queries";
@import "./../assets/scss/default";
@import "./../assets/scss/modules/container";

@keyframes expand-effect {
  from {
    border-radius: 50%;
    width: 0vh;
    height: 0vh;
    left: 50%;
    top: 218px;
    transform: translate(-50%, -50%);
  }
  to {
    border-radius: 50%;
    width: 300vh;
    height: 300vh;
    left: 50%;
    top: 218px;
    transform: translate(-50%, -50%);
  }
}

@keyframes opacity-effect-1 {
  from {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes opacity-effect-2 {
  from {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes opacity-effect-3 {
  from {
    opacity: 0;
  }
  67% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes opacity-effect-4 {
  from {
    opacity: 0;
  }
  72% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media all and #{$media-small} {
  @keyframes expand-effect {
    from {
      border-radius: 50%;
      width: 0vh;
      height: 0vh;
      left: 50%;
      top: 298px;
      transform: translate(-50%, -50%);
    }
    to {
      border-radius: 50%;
      width: 300vh;
      height: 300vh;
      left: 50%;
      top: 298px;
      transform: translate(-50%, -50%);
    }
  }
}

.status-page__status {
  position: relative;
  padding-top: 100px;
  padding-bottom: 82px;
  min-height: 100vh !important;
  overflow: hidden;
}

.status-page__status__blue-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #001CCF;
}

.status-page__status__white-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 2;
  animation: expand-effect 2.4s;
}

.status-page__status__container {
  @extend .container;
  position: relative;
  z-index: 3;
}

.status-page__status__header__clock {
  display: block;
  width: 197px;
  margin: 0 auto;
}

.status-page__status_clock-container {
  position: relative;
}

.status-page__status__header_check {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 55px;
}

.status-page__status__header h2 {
  display: block;
  margin-top: -20px;
  font-family: $font-family-primary;
  font-weight: 300;
  font-size: 1.375rem;
  line-height: 1.22;
  text-align: center;
  letter-spacing: -0.16px;
  color: black;
  animation: opacity-effect-1 0.8s;
}

.status-page__status__header-description {
  font-family: $font-family-primary;
  font-size: 0.875rem;
  line-height: 22px;
  text-align: center;
  color: $color-gray;
  margin-top: 24px;
  animation: opacity-effect-2 1s;
}

.status-page__status__header-protocol {
  font-family: $font-family-primary;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 22px;
  text-align: center;
  color: black;
  margin-top: 28px;
  animation: opacity-effect-3 1.2s;
}

.status-page__status__header-more-info {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 25px;
  z-index: 100;
  animation: opacity-effect-4 1.4s;
}

.status-page__status__header-more-info__container {
  @extend .container;
}

.status-page__status__header-more-info__text {
  display: block;
  font-family: $font-family-primary;
  font-size: 0.75rem;
  line-height: 16px;
  text-align: center;
  color: $color-gray;
}

@media all and #{$media-small} {
  .status-page__status {
    padding-top: 180px;
    padding-bottom: 148px;
  }

  .status-page__status__header h2 {
    margin-top: 0px;
    font-size: 1.75rem;
    line-height: 1.22;
  }

  .status-page__status__header-more-info {
    line-height: 22px;
  }

  .status-page__status__header-protocol {
    margin-top: 36px;
    font-size: 1rem;
  }

  .status-page__status__header-more-info {
    bottom: 50px;
  }

  .status-page__status__header-more-info__text {
    line-height: 1.84;
  }
}
</style>
