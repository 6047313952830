export const getConfigurationValue = (
  configurations,
  key,
  defaultValue = undefined
) => {
  let value = defaultValue;
  configurations.some((configuration) => {
    if (configuration.key === key) {
      if (configuration.type === 'json') {
        value = JSON.parse(configuration.value);
      } else {
        value = configuration.value;
      }
      return true;
    }
  });
  return value;
};


export const getConfigurationDescription = (
  configurations,
  key,
  defaultValue = undefined
) => {
  let description = defaultValue;
  configurations.some((configuration) => {
    if (configuration.key === key) {
      if (configuration.type === 'json') {
        description = JSON.parse(configuration.description);
      } else {
        description = configuration.description;
      }
      return true;
    }
  });
  return description;
};