import Vue from 'vue';
import VueRouter from 'vue-router';
import OfferPage from '@/pages/Offer.vue';
import LoadingPage from '@/pages/Loading.vue';
import StatusPage from '@/pages/Status.vue';
import ErrorPage from '@/pages/Error.vue';
import LoginPage from '@/pages/Login.vue';
import FaqPage from '@/pages/Faq.vue';
import SessionErrorPage from '@/pages/SessionError.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/oferta',
        name: 'offer',
        component: OfferPage
    },
    {
        path: '/carregando',
        name: 'loading',
        component: LoadingPage
    },
    {
        path: '/status',
        name: 'status',
        component: StatusPage
    },
    {
        path: '/erro',
        name: 'error',
        component: ErrorPage
    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage
    },

    {
        path: '/faq',
        name: 'faq',
        component: FaqPage
    },
    {
      path: '/sessionError',
      name: 'session-error',
      component: SessionErrorPage
    },
    {
        path: '*',
        name: 'index',
        component: null
    }
];

const router = new VueRouter({
    base: process.env.BASE_URL,
    mode: 'history',
    routes
});

export default router;