<template>
    <div class="cover">
        <img src="../assets/img/logo-tim-loading-page.svg" alt="teste" title="teste"/>
    </div>
</template>

<script>
  export default {
    name: 'InitialLoading',
  };
</script>

<style lang="scss">
  .cover {
    position: absolute;
    z-index: 100;
    background-color: #001ccf;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    }

    .cover img {
    width: 30%;
    }
</style>