import { getConfigurationValue } from '@/utils/configUtil';

class Glassbox {
 showGlassbox() {
    return getConfigurationValue(this.config, 'show-glassbox', '');
  }

 glassBoxScript(){
    return getConfigurationValue(this.config, 'glassbox_script', '');
}

}

export default Glassbox;



  