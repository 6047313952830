<template>
  <a
    class="action-button"
    :class="[
      `action-button--theme-${theme}`,
      {
        'action-button-disabled': disabled
      }
    ]"
    @click="onClick"
  >
    <span><slot></slot></span>
  </a>
</template>

<script>
  export const ThemeEnum = {
    DARK_FULL: 'dark_full',
    DARK_BORDERED: 'dark_bordered',
    LIGHT_FULL: 'light_full',
    LIGHT_BORDERED: 'light_bordered'
  };

  export default {
    name: 'ActionButton',
    props: {
      theme: {
        type: String,
        default: ThemeEnum.DARK_FULL
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      onClick() {
        if (!this.disabled) {
          this.$emit('click');
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "./../assets/scss/base/variables";

  .action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    border: 1px solid transparent;
    border-radius: 50px;
    text-align: center;
    font-family: $font-family-primary;
    font-size: 1rem;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    box-sizing: border-box;
    user-select: none;
  }

  /* theme dark full */

  .action-button.action-button--theme-dark_full {
    background-color: #1B00E1;
    border-color: #1B00E1;
    color: #FFFFFF;
    transition: background-color 0.4s ease;
  }

  .action-button.action-button--theme-dark_full:active {
    background-color: rgba(#1B00E1, 0.7);
  }

  .action-button-disabled.action-button.action-button--theme-dark_full {
    opacity: 0.2;
    cursor: default;
  }

    .action-button.action-button--theme-light_full {
    background-color: white;
    border-color: white;
    color: black  ;
    transition: background-color 0.4s ease;
  }

  .action-button.action-button--theme-light_full:active {
    background-color: rgba(white, 0.7);
  }

  .action-button-disabled.action-button.action-button--theme-light_full {
    opacity: 0.2;
    cursor: default;
  }
</style>
