<template>
  <main-template page="login" theme="white">
    <div class="login-page">
      <div class="login-page__form">
        <div class="login-page__form__header">
          <h1>Entrar</h1>
        </div>
        <form @submit.prevent="checkform()">
          <div class="form-item" id="divmsisdn" name="divmsisdn">
            <label v-if="erro || erroSMS" class="form-label-erro"
              >NÚMERO DA TIM</label
            >
            <label v-else class="form-label">NÚMERO DA TIM</label>
            <span>
              <span v-if="erro" class="form-icone-erro"
                ><img src="./../assets/img/ic-status-erro.svg"
              /></span>
              <span v-else-if="!erro && loginChecked" class="form-icone-erro"
                ><img src="./../assets/img/ic-status-check.svg"
              /></span>
            </span>
            <input
              @keyup="checkLogin()"
              id="msisdn"
              name="msisdn"
              v-model="msisdn"
              type="tel"
              placeholder="DDD + Número da TIM"
              class="form-input"
              minlength="14"
              maxlength="15"
              autocomplete="off"
              v-mask="['(##) #####-####']"
              required
            />
          </div>
          <div class="form-item" id="divpass" name="divpass">
            <label v-if="erro && !erroSMS" class="form-label-erro">SENHA</label>
            <label v-else class="form-label">SENHA</label>
            <span>
              <span v-if="erro && !erroSMS" class="form-icone-erro"
                ><img src="./../assets/img/ic-status-erro.svg"
              /></span>
              <span v-else-if="!erro && senhaChecked" class="form-icone-erro"
                ><img src="./../assets/img/ic-status-check.svg"
              /></span>
            </span>
            <input
              @keyup="checkSenha()"
              id="pass"
              v-model="password"
              name="pass"
              type="password"
              placeholder="Senha de 4 números"
              class="form-input"
              minlength="4"
              maxlength="4"
              autocomplete="off"
              required
            />
          </div>
        </form>

        <div class="mensagem-de-erro-ba">
          <p v-if="erro && !erroSMS" class="mensagem-de-erro">
            Usuário ou senha inválida. <br />
            Preencha novamente os campos de usuário e senha.
          </p>
          <p v-else-if="erro && erroSMS" class="mensagem-de-erro">
            Usuário inválido. <br />
            Preencha novamente o usuário.
          </p>
        </div>

        <div class="form-senha">
          <a @click="enviarSms()" class="form-senha__link"
            >Não sei minha senha</a
          >
        </div>
        <div>
          <div class="form-action">
            <action-button :disabled="botaoEntrar" @click="logar()"
              >Entrar Agora</action-button
            >
          </div>
        </div>
        <div v-if="enviado" class="form-link-enviado">
          <p class="form-link">
            Em poucos segundos você <br />
            receberá um SMS com o link <br />
            para redefinir sua senha.
          </p>
        </div>
      </div>
    </div>
  </main-template>
</template>

<script>
import MainTemplate from "@/templates/Main.vue";
import ActionButton from "@/components/ActionButton.vue";
import Application from "@/core/Application";
import { mapGetters } from "vuex";
import { getConfigurationValue } from "@/utils/configUtil";
import Tracker from "@/core/Tracker";

export default {
  name: "login",
  data() {
    return {
      logado: false,
      enviado: false,
      erro: false,
      erroSMS: false,
      loginChecked: false,
      senhaChecked: false,
      password: "",
      msisdn: "",
      botaoEntrar: true,
    };
  },
  components: {
    MainTemplate,
    ActionButton,
  },
  computed: {
    ...mapGetters(["config", "offer", "offerErrorCode", "erroDescription"]),
    apiErrorMessage() {
      return getConfigurationValue(this.config, "lbl-api-err", "");
    },
    api505ErrorMessage() {
        return getConfigurationValue(this.config, "eligibility-550-error-message", "")
    },
    accessErrorMessage() {
      return getConfigurationValue(this.config, "lbl-access-err", "");
    },
  },
  methods: {
    enviarSms() {
      if (this.msisdn == null || this.msisdn == "" || this.msisdn.length < 15) {
        this.enviado = false;
        this.erroSMS = true;
        let inputMsisdn = document.getElementById("msisdn");
        inputMsisdn.classList.add("erro");
        this.erro = true;
        let inputPass = document.getElementById("pass");
        inputPass.classList.remove("erro");
      } else {
        Application.sendSms(this.msisdn)
          .then(() => {
            this.removeErro();
            this.enviado = true;
          })
          .catch(() => {
            Application.showError({
              message: this.accessErrorMessage,
              action: "try_again",
            });
          });
      }
    },
    logar() {
      this.enviado = false;
      if (!this.senhaChecked || !this.loginChecked) {
        return this.erroLogar();
      } else {
        Application.loadEligibleOffer(this.msisdn, this.password)
            .then((loaded) => {
                let eventStatus = "Sucesso";

                if (!loaded) {
                eventStatus = "Falha";
                }
                this.trackLogin(eventStatus);

                if (loaded) {
                    this.$router.push({ name: "offer" });
                } else if (!loaded) {
                    if (this.offerErrorCode == 421) {
                        this.erroLogar();
                    } else if (this.offerErrorCode >= 400 && this.offerErrorCode <= 499) {
                        Application.showError({
                        message: this.accessErrorMessage,
                        action: "try_again",
                        });
                    } else if (this.offerErrorCode == 550) {
                        Application.showError({
                        message: this.api505ErrorMessage,
                        action: "",
                        });
                    } else {
                        Application.showError({
                        message: this.apiErrorMessage,
                        action: "",
                        });
                    }
                }
            })
            .catch(() => {
                Application.showError({
                message: this.apiErrorMessage,
                action: "try_again",
                });
            });
        }
    },
    erroLogar() {
      this.erroSMS = false;
      let inputMsisdn = document.getElementById("msisdn");
      let inputPass = document.getElementById("pass");
      inputPass.classList.add("erro");
      inputMsisdn.classList.add("erro");

      this.erro = true;
    },
    checkform(e) {
      e.preventDefault();
    },
    checkLogin() {
      this.removeErro();
      if (this.msisdn.length == 15) {
        this.loginChecked = true;
        return;
      } else {
        this.loginChecked = false;
        this.habitilitaBotaoEntrar();
        return;
      }
    },
    checkSenha() {
      this.removeErro();
      if (this.password.length == 4) {
        this.senhaChecked = true;
        this.habitilitaBotaoEntrar();
        return;
      } else {
        this.senhaChecked = false;
        this.habitilitaBotaoEntrar();
        return;
      }
    },
    removeErro() {
      this.erroSMS = false;
      this.erro = false;
      let inputMsisdn = document.getElementById("msisdn");
      let inputPass = document.getElementById("pass");
      inputPass.classList.remove("erro");
      inputMsisdn.classList.remove("erro");
    },
    habitilitaBotaoEntrar() {
      if (this.senhaChecked && this.loginChecked) {
        return (this.botaoEntrar = false);
      } else {
        return (this.botaoEntrar = true);
      }
    },
    trackLogin(eventStatus) {
      let erroDetail = this.apiErrorMessage;
      let eventLabel = "Tim Sync Login";
      if (this.offerErrorCode >= 400 && this.offerErrorCode <= 499) {
        erroDetail = this.accessErrorMessage;
      }
      if (this.offerErrorCode == 421) {
        eventLabel = "Tim Sync Usuario Senha Invalida";
        erroDetail = "Usuário ou senha inválida";
      }

      if (this.offerErrorCode == 421) {
        Tracker.trackLogin()
          .userLogin(this.msisdn)
          .userLineType("Móvel")
          .userLoginType("CPF")
          .userSegment("")
          .userSubSegment("")
          .userPlanCode(this.offer ? this.offer.plan.commercialCode : "")
          .userPlanName(this.offer ? this.offer.plugin.name : "")
          .userCPF(this.offer ? this.offer.userCPF : "")
          .eventLabel(eventLabel)
          .eventError(this.offerErrorCode ? this.offerErrorCode : "")
          .eventErrorDetail(this.offerErrorCode ? erroDetail : "")
          .eventCategory("6")
          .eventStatus(eventStatus)
          .emit();
      } else {
        Tracker.trackLogin()
          .userLogin(this.msisdn)
          .userLineType("Móvel")
          .userLoginType("CPF")
          .userSegment("")
          .userSubSegment("")
          .userPlanCode(this.offer ? this.offer.plan.commercialCode : "")
          .userPlanName(this.offer ? this.offer.plugin.name : "")
          .userCPF(this.offer ? this.offer.userCPF : "")
          .eventLabel(eventLabel)
          .eventError(this.offerErrorCode ? this.offerErrorCode : "")
          .eventErrorDetail(this.offerErrorCode ? erroDetail : "")
          .eventStatus(eventStatus)
          .emit();
      }
    },
  },
};
</script>


<style lang="scss">
@import "./../assets/scss/base/media-queries";
@import "./../assets/scss/default";
@import "./../assets/scss/modules/container";

.login-page {
  font-family: $font-family-primary;
  padding: 0px 20px;
  background-color: #ffffff;
}

.login-page__form {
  position: relative;
  padding-top: 96px;
}

.login-page__form__header {
  text-align: center;
  margin-bottom: 40px;
}

.login-page__form__header > h1 {
  font-size: 32px;
  font-weight: 300;
}

.form-item {
  width: 100%;
  margin-bottom: 24px;
  position: relative;
  display: block;
}

.form-label {
  font-family: $font-family-primary;
  width: 100%;
  display: block;
  font-size: 0.75rem;
  margin-bottom: 7px;
  color: #6c6c6c;
  text-transform: uppercase;
}

.form-input {
  font-family: $font-family-primary;
  width: 100%;
  opacity: 0.75;
  display: block;
  border: 0;
  border-bottom: 1px solid #000000;
  font-size: 1rem;
  color: #000000;
  border-radius: 0;
  outline: none;
  transition: border-color ease 0.3s;
  padding: 7px 0 13px 0;
  background: transparent;
}
.form-input.erro {
  border-bottom: 1px solid#ec0028;
}

vue-form .form-item:last-child {
  margin-bottom: 48px;
}

.form-senha {
  text-align: center;
}

.form-senha__link {
  font-size: 0.85rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: right;
  color: #0026d9;
}

.form-link {
  width: 199px;
  height: 68px;
  padding: 16px 16px 16px 13px;
  border-radius: 21px;
  background-color: #e1e5e8;
  font-family: $font-family-primary;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: normal;
  margin-left: 50%;
  transform: translateX(-50%);
}

.form-link-enviado {
  margin-top: 38px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.mensagem-de-erro {
  width: 100%;
  height: 32px;
  margin: 8px 0 46px;
  font-family: $font-family-primary;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.5px;
  text-align: center;
  color: #ec0028;
}
.mensagem-de-erro-backgroud {
  width: 100%;
  display: block;
  height: 640px;
  padding: 24px 20px 139px;
  background-color: #ffffff;
}
.form-label-erro {
  font-family: $font-family-primary;
  width: 100%;
  display: block;
  font-size: 0.75rem;
  margin-bottom: 7px;
  color: #ec0028;
  text-transform: uppercase;
}

.form-action {
  width: 100%;
  display: block;
  margin-top: 24px;
}
.form-icone-erro {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 22px;
  right: 0;
}
</style>
