import numeral from 'numeral';

export const locales = {
  pt_BR: {
    delimiters: {
      thousands: '.',
      decimal: ','
    },
    abbreviations: {
      thousand: 'm',
      million: 'mi',
      billion: 'bi',
      trillion: 'tri'
    },
    ordinal: function (number) {
      return `${number}º`;
    },
    currency: {
      symbol: 'R$'
    }
  }
};

Object.keys(locales).forEach((localeCode) => {
  numeral.register('locale', localeCode, locales[localeCode]);
});

export function setLocale(localeCode) {
  numeral.locale(localeCode);
}

export function formatMoney(value, symbol = false) {
  value = isNaN(value) ? value : Number(value);
  if (symbol) {
    return numeral(value).format('$ 0,0.00');
  }

  return numeral(value).format('0,0.00');
}
