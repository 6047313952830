<template>
  <label class="form-checkbox">
    <img
      v-if="!checked"
      class="form-checkbox__image"
      src="./../assets/img/form/checkbox.svg"
    />
    <img
      v-if="checked"
      class="form-checkbox__image"
      src="./../assets/img/form/checkbox-active.svg"
    />
    <input
      :id="checkboxID"
      class="form-checkbox__checkbox"
      type="checkbox"
      :value="value"
      @input="handleInput"
    />
    <label :for="labelFor" class="form-checkbox__label"><slot/></label>
  </label>
</template>

<script>
  import uniqueID from '@/helpers/uniqueID';

  export default {
    name: 'FormCheckbox',
    props: {
      id: {
        type: String,
        default: ''
      },
      value: {
        type: Boolean,
        default: false
      },
      labelClick: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        checkboxID: '',
        labelFor: '',
        checked: false
      };
    },
    methods: {
      handleInput(event) {
        this.$emit('input', event.target.checked);
        this.checked = event.target.checked;
      }
    },
    created() {
      this.checkboxID = this.id ? this.id : uniqueID('checkbox');
      this.checked = this.value;

      if (this.labelClick) {
        this.labelFor = this.checkboxID;
      }
    }
  };
</script>

<style lang="scss">
  @import "./../assets/scss/base/variables";

  .form-checkbox {
    display: block;
    position: relative;
  }

  .form-checkbox__image {
    display: block;
    position: absolute;
    width: 25px;
    left: 0;
    top: 0;
    z-index: 1;
    pointer-events: none;
  }

  .form-checkbox__checkbox {
    display: block;
    position: absolute;
    width: 25px;
    height: 25px;
    left: 0;
    top: 0;
    z-index: 2;
    opacity: 0;
  }

  .form-checkbox__label {
    display: block;
    padding-left: 38px;
    font-family: $font-family-primary;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.5;
    color: #4b4b4b;
    letter-spacing: -0.3px;
  }

  .form-checkbox__label a {
    text-decoration: underline;
    cursor: pointer;
  }

  .form-checkbox__label a:hover {
    color: #001CCF;
  }

  .form-checkbox__label a:active {
    color: darken(#001CCF, 10);
  }
</style>
