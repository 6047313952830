import UserAgentParser from 'ua-parser-js';

class Device {
  info = null;

  constructor() {
    const parser = new UserAgentParser();
    this.info = parser.getResult();
  }

  getOS() {
    return this.info.os.name || '';
  }

  getModel() {
    return this.info.device.model || '';
  }

  getManufacturer() {
    return this.info.device.vendor || '';
  }

  getOSVersion() {
    return this.info.os.version || '';
  }
}

export default Device;
