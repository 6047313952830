<template>
  <main-template>
    <div class="error-page">
      <!-- error -->
      <div class="error-page__error">
        <div class="error-page__error__container">
          <div class="error-page__error__image">
            <img
              class="error-page__error__image__clock"
              src="./../assets/img/watch-2.svg"
              alt="Relógio Digital"
              title="Relógio Digital"
            />
            <img
              class="error-page__error__image__error"
              src="./../assets/img/error.svg"
              alt="Erro"
              title="Erro"
            />
          </div>
          <h2 class="error-page__error__message" v-html="errorMessage? errorMessage : 'Ocorreu um erro'"></h2>
        </div>
        <div v-if="errorAction !== ''" class="error-page__error__control">
          <div class="error-page__error__control__container">
            <action-button
              v-if="errorAction === 'try_again'"
              @click="handleTryAgain"
              theme="light_full"
            >Tentar novamente</action-button>
          </div> 
        </div>
      </div>
      <!-- end error -->
    </div>
  </main-template>
</template>

<script>
  import { mapState } from 'vuex';
  import Application from '@/core/Application';
  import MainTemplate from '@/templates/Main.vue';
  import ActionButton from '@/components/ActionButton.vue';

  export default {
    name: 'error',
    computed: {
      ...mapState([
        'errorStatus',
        'errorMessage',
        'errorAction',
        'errorOnCreated',
        'errorOnClickAction'
      ])
    },
    methods: {
      handleTryAgain() {
        if (this.errorOnClickAction) {
          this.errorOnClickAction();
        }

        Application.tryAgain();
      },
      handleReturnToLogin() {
        this.$router.push({ path: '/login' })
      }
    },
    created() {
      if (this.errorOnCreated) {
        this.errorOnCreated();
      }
    },
    components: {
      MainTemplate,
      ActionButton
    },
  };
</script>

<style lang="scss">
  @import "./../assets/scss/base/media-queries";
  @import "./../assets/scss/default";
  @import "./../assets/scss/modules/container";

  @keyframes leave-effect {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes clock-effect {
    from {
      margin-top: -200px;
    }
    to {
      margin-top: 52px;
    }
  }

  .error-page__error {
    position: relative;
    padding-top: 104px;
    padding-bottom: 114px;
    min-height: 100vh !important;
  }

  .error-page__error__container {
    @extend .container;
  }

  .error-page__error__image {
    position: relative;
    animation: clock-effect 0.3s 1;
  }

  .error-page__error__image__clock {
    display: block;
    width: 197px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }

  .error-page__error__image__error {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 55px;
    z-index: 1;
  }

  .error-page__error__message {
    display: block;
    font-family: $font-family-primary;
    font-weight: 300;
    font-size: 1.25rem;
    line-height: 1.25;
    text-align: center;
    color: white;
  }

  .error-page__error__control {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    padding-bottom: 32px;
  }

  .error-page__error__control__container {
    @extend .container;
  }

  @media all and #{$media-xsmall} {
    .error-page__error__message {
      font-size: 1.375rem;
    }
  }

  @media all and #{$media-small} {
    .error-page__error {
      padding-top: 180px;
    }

    .error-page__error__message {
      font-size: 1.75rem;
      letter-spacing: -1px;
    }
  }
</style>
